<template>
	<div class="order-details">
		<div class="top">
			<span>￥{{ service.service_amount }}</span>
			<img src="@/assets/after-sale.png" alt />
		</div>

		<div class="tips-box">提示：{{ tipText }}</div>

		<template v-if="service.service_types == 2 && service.service_status > 1">
			<div class="express-box">
				<div class="row">
					<div class="left">快递名称</div>
					<input class="right" type="text" placeholder="请填写快递名称" v-model="logisticsCompany"
						v-if="service.service_status == 2" />
					<div class="right" type="text" v-else>{{ service.logistics_company }}</div>
				</div>
				<div class="row">
					<div class="left">快递单号</div>
					<input class="right" type="text" placeholder="请填写退货的快递单号" v-model="logisticsCode"
						v-if="service.service_status == 2" />
					<div class="right" type="text" v-else>{{ service.logistics_code }}</div>
				</div>
			</div>
			<div class="btn-sub" @click="toSubmit" v-if="service.service_status == 2">提交</div>
		</template>

		<div class="info-box" v-if="service.service_types == 2 && service.service_status > 1">
			<div class="info-box-title">商家售后信息</div>
			<div class="info-row">退货联系人：{{ service.address_contacts }}</div>
			<div class="info-row">联系人电话：{{ service.address_phone }}</div>
			<div class="info-row">退货地址：{{ service.address_details }}</div>
		</div>

		<div class="info-box">
			<div class="info-box-title">售后信息</div>
			<div class="info-row">售后类型： {{ service.service_reason }}</div>
			<div class="info-row">申请金额： ￥{{ service.apply_amount }}</div>
			<div class="info-row">实退金额： ￥{{ service.service_amount }}</div>
			<div class="info-row">申请时间： {{ service.create_time }}</div>
			<div class="info-row">联系电话： {{ service.user_phone }}</div>
			<div class="info-row">申请说明： {{ service.service_remark }}</div>
			<div class="info-row" v-if="service.admin_remark != ' '">处理备注： {{ service.admin_remark }}</div>
		</div>

		<div class="goods-list">
			<router-link tag="div" class="goods-item" :to="'/ShopGoodsDetails?types=1&goods_id=' + order.goods_id">
				<img :src="order.goods_image" alt />
				<div class="goods-info">
					<p class="goods-name">{{ order.goods_name }}</p>
					<div class="goods-spec">{{ order.specs_item_value }}</div>
					<div class="flex-between">
						<div class="goods-price">￥{{ order.goods_price }}</div>
						<div class="goods-num">×{{ order.goods_nums }}</div>
					</div>
				</div>
			</router-link>
		</div>
		<div class="bot-info">
			<div class="row">
				<div>商品总计</div>
				<div>￥{{ order.goods_amount }}</div>
			</div>
			<div class="row">
				<div>运费</div>
				<div>￥{{ order.postage_amount }}</div>
			</div>
			<div class="row">
				<div>实付款</div>
				<div style="color: #e40011;">￥{{ order.order_amount }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAfterOrderDetails,
		serviceHandleDelivery
	} from "@/http/api";
	export default {
		components: {},
		data() {
			return {
				userToken: "",
				service_id: '',
				order: {},
				service: {},
				logisticsCompany: "",
				logisticsCode: "",
				isPost: false
			};
		},
		mounted() {
			if (this.$route.query.service_id) {
				this.userToken = this.$LStorage.getItem("userToken").user_token;
				this.service_id = this.$route.query.service_id
				this.getAfterOrderDetails();
			} else {
				this.$dialog.alert({
					message: '售后订单页面参数异常,返回上一页看看',
				}).then(() => {
					this.$router.go(-1);
				});
			}
		},
		methods: {
			// 获取售后详情
			async getAfterOrderDetails() {
				const res = await getAfterOrderDetails({
					data: {
						user_token: this.userToken,
						service_id: this.service_id
					}
				});
				this.order = res.data.order_info;
				this.service = res.data.service_info;
			},
			// 点击提交
			toSubmit() {
				if (this.isPost) {
					this.$toast("请勿重复提交");
				} else {
					if (this.logisticsCompany == "" || this.logisticsCode == "") {
						this.$toast("快递公司、快递单号不能为空");
						return;
					} else {
						this.serviceHandleDelivery();
					}
				}
			},
			// 提交快递单号
			async serviceHandleDelivery() {
				this.isPost = true;
				const res = await serviceHandleDelivery({
					data: {
						user_token: this.userToken,
						service_id: this.service_id,
						logistics_company: this.logisticsCompany,
						logistics_code: this.logisticsCode
					}
				});
				this.isPost = false;
				if (res.code == 200) {
					this.$toast.success("提交成功");
					this.getAfterOrderDetails();
				} else {
					this.$toast(res.msgs);
				}
			}
		},
		computed: {
			tipText() {
				let status = this.service.service_status,
					types = this.service.service_types;
				switch (status) {
					case 0:
						return "您的售后申请有误，售后已关闭，请重新提交";
					case 1:
						return "售后申请已提交, 等待平台同意，您可至个人中心与平台客服联系";
					case 2:
						return "商家已同意退货退款, (如已签收,请将物品寄回至页面上的商家售后地址，并填写，提交退货快递信息; 如未签收，请拒签商品，物流信息请填写商家发货的物流信息) ";
					case 3:
						if (types == 1)
							return "售后申请已提交, 等待平台同意，您可至个人中心与平台客服联系";
						if (types == 2)
							return "您的售后物流已提交, 商家确认收货后，会尽快处理，请耐心等待";
					case 4:
						return "您的申请已处理，我们将在7个工作日内进行退款操作。";
					case 5:
						return "您的售后已处理完成，款项将原路返回至您的支付账户，请自行留意查看。";
				}
			}
		},
		filters: {}
	};
</script>

<style lang="scss" scoped>
	.order-details {
		background: #f6f6f6;
		box-sizing: border-box;
		min-height: 100vh;
		padding-bottom: 1.2rem;

		* {
			box-sizing: border-box;
		}

		.top {
			height: 1.62rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background: #e40011;
			color: #fff;
			font-size: 0.36rem;
			margin-bottom: 0.3rem;
			line-height: 2;

			img {
				height: 0.54rem;
				width: 0.54rem;
			}
		}

		.tips-box {
			background: #fff0e7;
			margin: 0 3vw 0.25rem;
			border-radius: 0.1rem;
			font-size: 0.24rem;
			color: #964703;
			line-height: 1.5;
			padding: 0.15rem;
		}

		.express-box {
			background: #fff;
			border-radius: 0.1rem;
			font-size: 0.28rem;
			margin: 0 3vw 0.25rem;
			overflow: hidden;
			padding: 0 0.15rem;

			.row {
				height: 1.1rem;
				display: flex;
				align-items: center;

				.right {
					font-size: 0.26rem;
					width: 60vw;
					margin-left: 8vw;
				}
			}

			.row:first-child {
				border-bottom: 1px solid #eee;
			}
		}

		.btn-sub {
			background: #e50012;
			color: #fff;
			width: 90vw;
			height: 0.85rem;
			margin: 0 5vw 0.25rem;
			border-radius: 0.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.3rem;
		}

		.goods-list {
			width: 94vw;
			background: #fff;
			// padding: .2rem 3vw;
			margin: 0 3vw 0.2rem;

			.goods-list-title {
				height: 0.9rem;
				display: flex;
				align-items: center;
				padding-left: 0.2rem;
				font-size: 0.28rem;
				border-bottom: 1px solid #eee;
			}

			.goods-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.2rem;

				img {
					width: 24vw;
					height: 24vw;
					border-radius: 5px;
					object-fit: cover;
				}

				.goods-info {
					width: 60vw;
					height: 24vw;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.goods-name {
						font-size: 0.28rem;
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.goods-spec {
						background: #f5f5f5;
						border-radius: 5px;
						padding: 0.1rem 0.15rem;
						font-size: 0.24rem;
						color: #848484;
						// width: max-content;
					}

					.goods-num {
						font-size: 0.24rem;
					}

					.goods-price {
						font-size: 0.24rem;
						color: #da1313;

						span {
							font-size: 0.3rem;
							font-weight: bold;
						}
					}
				}
			}
		}

		.bot-info {
			background: #fff;
			border-radius: 8px;
			overflow: hidden;
			width: 94vw;
			margin: 0 3vw;
			height: 2.4rem;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;

			.row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 0.24rem;
				// height: 1.2rem;
				font-size: 0.28rem;

				div {
					display: flex;
					align-items: center;
				}

				img {
					height: 0.4rem;
					margin-right: 5px;
				}
			}
		}

		.info-box {
			background: #fff;
			padding: 0.2rem 0.3rem;
			border-radius: 5px;
			width: 94vw;
			margin: 3vw 3vw 0.3rem;

			.info-box-title {
				font-size: 0.28rem;
				position: relative;
				margin-bottom: 5px;
			}

			.info-box-title::before {
				position: absolute;
				content: "";
				background: #da1313;
				width: 4px;
				height: 16px;
				left: -8px;
				top: 4px;
				margin-bottom: 10px;
			}

			.info-row {
				font-size: 0.26rem;
				line-height: 2;

				span {
					background: #da1313;
					color: #fff;
					font-size: 0.26rem;
					height: 0.38rem;
					line-height: 0.38rem;
					width: 0.8rem;
					text-align: center;
					border-radius: 5px;
					display: inline-block;
					margin-left: 10px;
				}
			}
		}

		.footer {
			position: fixed;
			bottom: 0;
			left: 0;
			background: #fff;
			width: 100vw;
			height: 1.2rem;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 3vw;

			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #bbbbbb;
				color: #585858;
				font-size: 0.26rem;
				border-radius: 0.1rem;
				height: 0.56rem;
				padding: 0 0.18rem;
				margin-left: 0.35rem;
			}

			.red {
				border: 1px solid #e50012;
				color: #e50012;
			}
		}
	}
</style>
